<template>
  <div class="iseq-sample-detail">
    <v-iseq-card
      :title="`Sample: ${sample.details.name}`"
      class="iseq-sample-detail__base-info"
    >
      <template #title-row>
        <div class="iseq-sample-detail__base-info__button-row">
          <v-iseq-btn
            v-if="permissionRequired('sampleEdit')"
            type="secondary"
            @click="changeNameDialog = true"
          >
            Edit
          </v-iseq-btn>

          <v-iseq-btn
            v-if="permissionRequired('sampleDelete')"
            type="red"
            ml
            @click="deleteDialog = true"
          >
            Delete sample
          </v-iseq-btn>
        </div>
      </template>

      <div class="iseq-sample-detail__base-info__dates-row">
        <div class="">
          <v-icon class="icon">
            mdi-account
          </v-icon>
          <span class="thin">Creator: </span>
          <span class="thick">{{ sample.details.creator ? sample.details.creator.email : "Unknown" }}</span>
        </div>

        <div class="">
          <v-icon class="icon">
            mdi-calendar-month
          </v-icon>
          <span class="thin">Valid until: </span>
          <span class="thick">{{ sample.details.validityDate }}</span>
        </div>
      </div>

      <v-divider :inset="false" />

      <div class="iseq-sample-detail__base-info__description">
        {{ sample.details.description }}
      </div>

      <v-iseq-field :values="descriptionFields" />
    </v-iseq-card>

    <v-iseq-card
      v-if="permissionRequired('analysisAdd') || (permissionRequired('sampleProlong') && isAdmin)"
      no-title
      no-bottom-margin
      class="iseq-sample-detail__actions"
    >
      <div class="iseq-sample-detail__actions__buttons">
        <v-iseq-btn
          v-if="permissionRequired('analysisAdd')"
          large
          width="wide"
          mr
          @click.prevent="startNewAnalysis"
        >
          New analysis
        </v-iseq-btn>

        <v-iseq-btn
          v-if="permissionRequired('sampleProlong') && isAdmin"
          large
          @click="extendValidityDialog=true"
        >
          Extend sample validity
        </v-iseq-btn>
      </div>
    </v-iseq-card>

    <v-files-upload-card
      v-if="ongoingUploads>0"
      :filter-sample="sample.details.uuid"
    />

    <v-iseq-card
      title="Sample details"
      tabs-only
      class="iseq-sample-detail__tabs"
    >
      <template #title-row>
        <div class="iseq-sample-detail__tabs__wrapper">
          <v-tabs
            v-model="tab"
            height="64px"
            color="primary"
          >
            <v-tab
              v-if="permissionRequired('analysisView')"
              href="#analyses"
              class="iseq-sample-detail__tabs__wrapper__tab"
            >
              <v-icon class="icon">
                mdi-account-multiple
              </v-icon>
              Analyses
            </v-tab>

            <v-tab
              v-if="permissionRequired('sampleFilesView')"
              href="#files"
              class="iseq-sample-detail__tabs__wrapper__tab"
            >
              <v-icon class="icon">
                mdi-folder
              </v-icon>
              Files
            </v-tab>

            <v-tab
              href="#properties"
              class="iseq-sample-detail__tabs__wrapper__tab"
            >
              <v-icon class="icon">
                mdi-folder
              </v-icon>
              Properties
            </v-tab>
          </v-tabs>
        </div>
      </template>
    </v-iseq-card>

    <v-iseq-card
      no-title
      no-bottom-margin
      class="iseq-sample-detail__analysis"
    >
      <v-tabs-items
        touchless
        :value="tab"
      >
        <v-tab-item value="analyses">
          <div class="iseq-sample-detail__analysis__title">
            Analyses
          </div>

          <v-data-table
            :headers="analysesHeaders"
            :footer-props="{
              'items-per-page-options': itemsPerPageOptions
            }"
            :items="analysesContent"
            :server-items-length="analysesTotalElements"
            :options.sync="optionsAnalyses"
          >
            <template #[`item.name`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-sample-detail__analysis__field">
                  {{ item.name }}
                </div>
              </router-link>
            </template>

            <template #[`item.status`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-sample-detail__analysis__field">
                  {{ item.status }}
                </div>
              </router-link>
            </template>

            <template #[`item.started`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-sample-detail__analysis__field">
                  {{ item.started }}
                </div>
              </router-link>
            </template>

            <template #[`item.finished`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-sample-detail__analysis__field">
                  {{ item.finished }}
                </div>
              </router-link>
            </template>

            <template #[`item.chargedCost`]="{ item }">
              <router-link :to="`/analyses/${item.uuid}`">
                <div class="iseq-sample-detail__analysis__field">
                  {{ item.chargedCost }} credits
                </div>
              </router-link>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item
          v-if="permissionRequired('sampleFilesView')"
          value="files"
        >
          <div class="iseq-sample-detail__files__title">
            Files

            <div>
              <v-dialog
                v-if="permissionRequired('fileUpload')"
                v-model="uploadFileDialog"
                persistent
                max-width="600px"
              >
                <template #activator="{ on: onDialog }">
                  <v-tooltip
                    v-if="userCredits === 0"
                    top
                  >
                    <template #activator="{ on: onTooltip, attrs }">
                      <div
                        v-bind="attrs"
                        class="asd"
                        v-on="onTooltip"
                      >
                        <v-iseq-btn
                          small
                          disabled
                          ml
                          plus-button
                        />
                      </div>
                    </template>
                    <span>Your account balance is to low</span>
                  </v-tooltip>

                  <v-iseq-btn
                    v-else
                    small
                    ml
                    plus-button
                    v-on="onDialog"
                  />
                </template>

                <v-card class="iseq-sample-detail__files-upload">
                  <div class="iseq-sample-detail__files-upload__title-row">
                    <div class="iseq-sample-detail__files-upload__title-row__title">
                      Upload file
                    </div>
                  </div>

                  <div class="iseq-sample-detail__files-upload__tabs">
                    <v-tabs
                      v-model="uploadTab"
                      height="64px"
                      color="primary"
                      centered
                    >
                      <v-tab
                        key="Drive"
                        class="iseq-sample-detail__files-upload__tabs__tab"
                      >
                        <v-icon class="mr-4 title">
                          mdi-account-multiple
                        </v-icon>
                        From your computer
                        <v-badge
                          v-if="uploadErrors.disk.length"
                          content="!"
                          color="danger"
                          offset-x="-5"
                          offset-y="4"
                        />
                      </v-tab>
                      <v-tab
                        key="Url"
                        class="iseq-sample-detail__files-upload__tabs__tab"
                      >
                        <v-icon class="mr-4 title">
                          mdi-folder
                        </v-icon>
                        From URL
                        <v-badge
                          v-if="uploadErrors.url.length"
                          content="!"
                          color="danger"
                          offset-x="-5"
                          offset-y="4"
                        />
                      </v-tab>
                      <!--                                            <v-tab class="iseq-sample-detail__files-upload__tabs__tab" key="ftp">-->
                      <!--                                                <v-icon class="mr-4 title">mdi-folder</v-icon>-->
                      <!--                                                From FTP-->
                      <!--                                                <v-badge v-if="uploadErrors.ftp.length"-->
                      <!--                                                         content="!"-->
                      <!--                                                         color="danger"-->
                      <!--                                                         offset-x="-5"-->
                      <!--                                                         offset-y="4"/>-->
                      <!--                                            </v-tab>-->
                    </v-tabs>
                  </div>

                  <div class="iseq-sample-detail__files-upload__files-form shadow">
                    <v-iseq-files-upload
                      :files-to-upload.sync="filesToUpload"
                      :urls-to-upload.sync="urlsToUpload"
                      :ftp.sync="ftp"
                      :upload-errors.sync="uploadErrors"
                      :existing-sample="true"
                      :upload-tab="uploadTab"
                    />

                    <div class="iseq-sample-detail__files-upload__extensions">
                      Allowed files extensions: {{ workflowInputExtensionsLong }}
                    </div>
                  </div>

                  <div class="iseq-sample-detail__files-upload__info">
                    <v-icon
                      color="primary"
                      class="iseq-sample-detail__files-upload__info__icon"
                    >
                      mdi-alert-circle
                    </v-icon>

                    <div class="iseq-sample-detail__files-upload__info__text">
                      Currently implemented workflows work only on files in GRCh38/hg38 coordinates (refer to vcf,
                      vcf.gz, gvcf, gvcf.gz, bam, bed, bed.gz files).<br>
                      To liftover bed/bed.gz files from GRCh37(hg19) to GRCh38(hg38) use "Liftover tool for .bed files
                      [GRCh37(hg19) to GRCh38(hg38)]" workflow available in "Workflows" at the navigation menu.
                    </div>
                  </div>

                  <div class="iseq-sample-detail__files-upload__buttons">
                    <v-iseq-btn
                      type="secondary"
                      width="100px"
                      @click="clearUploadFiles()"
                    >
                      Close
                    </v-iseq-btn>

                    <v-tooltip
                      :disabled="userCredits > 0"
                      top
                    >
                      <template #activator="{on}">
                        <span v-on="on">
                          <v-iseq-btn
                            :disabled="!areUploadErrorsEmpty"
                            ml
                            width="100px"
                            @click="uploadFiles()"
                          >
                            Upload
                          </v-iseq-btn>
                        </span>
                      </template>
                      <span>Your account balance is to low</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>

          <div
            v-if="filesLoadingSpinner"
            class="iseq-sample-detail__analysis__file__spinner"
          >
            <v-iseq-loading-spinner text="Loading files..." />
          </div>

          <v-tree-menu
            v-if="sample.filesTree.id"
            :tree="sample.filesTree"
            type="sample"
            :sample-uuid="sample.details.uuid"
            :project-uuid="sample.details.project.uuid"
            :organization-uuid="sample.details.project.organizationUuid"
            :show-children="true"
          />
        </v-tab-item>

        <v-tab-item value="properties">
          <div class="iseq-sample-detail__properties__title">
            Properties
          </div>

          <v-divider class="mb-8" />

          <v-iseq-properties
            :properties="sample.details.properties"
            @addProperty="addProperty"
            @deleteProperty="deleteProperty"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-iseq-card>

    <iseq-confirmation-modal
      v-if="extendValidityDialog"
      width="300px"
      header="Extend sample validity"
      confirm-button-type="primary"
      confirm-button-text="Extend"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionConfirmed="extendValidity()"
      @actionRejected="extendValidityDialog = false"
    >
      <v-select
        v-model="validityExtension"
        outlined
        hide-details
        :items="validityExtensions"
        item-text="text"
        item-value="value"
        label="Extend by"
      />
    </iseq-confirmation-modal>

    <iseq-confirmation-modal
      v-if="changeNameDialog"
      width="800px"
      header="Update sample data"
      confirm-button-type="primary"
      confirm-button-text="Update"
      reject-button-type="secondary"
      reject-button-text="Close"
      :confirm-button-disabled="!isNameFormValid"
      @actionRejected="changeNameDialog = false"
      @actionConfirmed="updateSample(uuid)"
    >
      <div>
        <v-form
          ref="nameForm"
          v-model="isNameFormValid"
        >
          <v-text-field
            v-model="newSampleInfo.name"
            label="New sample name"
            outlined
            :rules="nameRules"
            counter="50"
          />
        </v-form>

        <v-textarea
          v-model="newSampleInfo.description"
          label="Description"
          auto-grow
          outlined
        />
      </div>
    </iseq-confirmation-modal>

    <iseq-confirmation-modal
      v-if="deleteDialog"
      width="600px"
      header="Delete sample"
      confirm-button-type="primary"
      confirm-button-text="Delete"
      :confirm-button-disabled="sample.runningAnalyses.length > 0"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="deleteDialog = false"
      @actionConfirmed="deleteSample()"
    >
      <template v-if="sample.runningAnalyses.length === 0">
        <span>
          Keep in mind that all analyses performed on this sample will be deleted along with it.<br>
          <br>
          In order to delete sample enter its name ({{ sample.details.name }}) below and click
          "Delete" button
        </span>

        <div>
          <v-text-field
            v-model="deleteDialogEnteredName"
            label="Sample's name"
          />
        </div>

        <span
          v-if="deletedNotification"
          class="error-text"
        >
          {{ deletedNotification }}
        </span>
      </template>

      <template v-else>
        <span>
          Following analysis must be completed or terminated in order to delete sample  {{ sample.details.name }}:
        </span>

        <div
          v-for="analysis in sample.runningAnalyses"
          :key="analysis.uuid"
        >
          <span>
            <router-link :to="`/analyses/${analysis.uuid}`">
              - <span class="link"> {{ analysis.name }}</span>
            </router-link><br>
          </span>
        </div>
      </template>
    </iseq-confirmation-modal>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import TreeMenu from '@/components/ui/TreeMenu.vue'
  import IseqField from "@/components/ui/IseqField.vue";
  import {permissionCheck} from "@/plugins/permissions";
  import LoadingSpinner from "@/components/ui/LoadingSpinner.vue";
  import {
    generatePagingData,
    pagingDataParse
  } from "@/plugins/utils";
  import Properties from "./components/Properties.component"
  import FilesUpload from "./components/FilesUpload.component"
  import filesUploadCard from "@/components/ui/filesUploadCard.component.vue";
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {

    name: "Sample",

    components: {
      IseqConfirmationModal,
      "v-tree-menu": TreeMenu,
      "v-iseq-field": IseqField,
      "v-iseq-loading-spinner": LoadingSpinner,
      "v-iseq-properties": Properties,
      "v-iseq-files-upload": FilesUpload,
      "v-files-upload-card": filesUploadCard,
      "v-iseq-card": IseqCard,
      "v-iseq-btn": IseqButton
    },

    data: function () {
      return {
        itemsPerPageOptions: [10, 20, 30, -1],
        optionsAnalyses: {},
        changeNameDialog: false,
        newSampleName: '',
        newSampleDescription: '',
        deleteDialog: false,
        deleteDialogEnteredName: "",
        deletedNotification: "",
        uploadFileDialog: false,
        extendValidityDialog: false,
        isNameFormValid: false,
        nameRules: [
          v => (!!v && v.length > 0) || "Name cannot be empty",
          v => v.length <= 50 || "Name cannot be longer than 50 characters",
          v => (!this.samplesMinimal.some(element => element.name === v) || v === this.sample.details.name) || "Sample named " + v + " already exists in this project",
          v => v === v.replace(/\s/gi, "_").replace(/\W/g, '') || "Name can contain only letters, numbers and underscores"
        ],
        tab: "properties",
        uploadTab: 0,
        optionsOpener: {},
        files: [],
        items: [],
        uuid: undefined,
        analysesHeaders: [
          {text: 'Name', value: 'name'},
          {text: 'Status:', value: 'status'},
          {text: 'Started', value: 'started'},
          {text: 'Finished', value: 'finished'},
          {text: 'Cost', value: 'chargedCost'}
        ],
        validityExtension: 7,
        validityExtensions: [
          {text: "7 days", value: 7},
          {text: "30 days", value: 30},
          {text: "60 days", value: 60},
          {text: "90 days", value: 90}
        ],
        uploadErrors: {
          disk: [],
          url: [],
          ftp: []
        },
        filesToUpload: {},
        urlsToUpload: {},
        ftp: {
          filename: '',
          password: '',
          url: '',
          username: '',
        },
        filesLoadingSpinner: true
      }
    },
    computed: {
      ...mapState('user', ['userCredits', 'isAdmin']),
      ...mapState('sample', ['sample', 'samplesMinimal', 'newSampleInfo', 'analysesContent', 'analysesTotalElements']),
      ...mapState('workflow', ['workflowInputExtensionsLong']),
      ...mapState('organization', ['organization']),
      ...mapState('project', ['project']),
      ...mapState('fileUploads', ['myUploadingFiles', 'othersUploadingFiles']),
      descriptionFields() {
        const tempSample = {...this.sample};
        return [
          {
            icon: "mdi-account-multiple",
            name: "Organization: ",
            value: this.sample.details.project.organizationName,
            route: "/organizations/" + this.sample.details.project.organizationUuid
          },
          {
            icon: "mdi-folder-multiple",
            name: "Project: ",
            value: this.sample.details.project.name,
            route: "/projects/" + this.sample.details.project.uuid
          },
          {
            icon: "mdi-database",
            name: "Size: ",
            value: tempSample.size
          }
        ]
      },
      sampleFilesTree() {
        return this.sample.filesTree;
      },
      areUploadErrorsEmpty() {
        let errors = 0;
        Object.keys(this.uploadErrors).map(type => errors += this.uploadErrors[type].length);
        return !errors;
      },
      ongoingUploads() {
        return this.myUploadingFiles.filter(item => item.sampleUuid === this.sample.details.uuid).length + this.othersUploadingFiles.filter(item => item.sampleUuid === this.sample.details.uuid).length;
      }
    },
    watch: {
      optionsAnalyses: {
        handler() {
          this.$store.dispatch('sample/getAnalysisBySample', {
            uuid: this.uuid,
            data: pagingDataParse({
              sortDesc: this.optionsAnalyses.sortDesc,
              sortBy: this.optionsAnalyses.sortBy,
              itemsPerPage: this.optionsAnalyses.itemsPerPage,
              totalElements: this.analysesTotalElements,
              page: this.optionsAnalyses.page
            })
          });
        },
        deep: true,
      },
      sampleFilesTree(newFilesTree) {
        this.filesLoadingSpinner = !newFilesTree.id;
      },
      project(newProject) {
        if (!newProject.uuid) {
          this.$router.push('/');
        } else if (newProject.uuid !== this.sample.details.project.uuid && this.isSampleValid) {
          this.$router.push('/samples');
        } else {
          if (this.permissionRequired('analysisView')) {
            this.tab = "analyses";
          } else if (this.permissionRequired('sampleFilesView')) {
            this.tab = "files";
          } else {
            this.tab = "properties";
          }
        }
      }
    },
    created: function () {
      if (this.permissionRequired('analysisView')) {
        this.tab = "analyses";
      } else if (this.permissionRequired('sampleFilesView')) {
        this.tab = "files";
      } else {
        this.tab = "properties";
      }
      this.$log.debug("vue.sample.created");
      this.uuid = this.$route.params.uuid;
      this.$store.dispatch('workflow/getInputExtensions');
      this.$store.dispatch('sample/getSample', this.uuid);
      this.$store.dispatch('sample/getAnalysisBySample', {uuid: this.uuid, data: generatePagingData({})});
      this.$store.dispatch('sample/getSampleListMinimal');
    },
    beforeDestroy() {
      this.$store.commit('sample/resetContentAnalyses');
      this.$store.commit('sample/cleanSample');
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      uploadFiles() {
        this.uploadFileDialog = false;

        const paramsFtp = {
          uuid: this.uuid,
          ftp: this.ftp
        };

        let formData = new FormData();
        let fileSizeSum = 0;
        for (let i = 0; i < Object.keys(this.filesToUpload).length; i++) {
          let key = Object.keys(this.filesToUpload)[i];
          formData.append('file' + i + 'size', this.filesToUpload[key].size);
          formData.append('file' + i + 'lastModified', this.filesToUpload[key].lastModified);
          formData.append('file' + i, this.filesToUpload[key]);
          fileSizeSum += this.filesToUpload[key].size;
        }

        this.$store.dispatch('sample/addFile', {
          fileSizeSum: fileSizeSum,
          uuid: this.uuid,
          formData: formData
        });

        for (let node in this.urlsToUpload) {
          this.$store.dispatch('sample/addFileFromURL', {
            uuid: this.uuid,
            url: encodeURI(this.urlsToUpload[node].url),
            name: this.urlsToUpload[node].name,
            username: this.urlsToUpload[node].username,
            password: this.urlsToUpload[node].password
          });
        }

        this.$store.dispatch('sample/getFilesTree', {sampleUuid: this.uuid});

        if (paramsFtp.ftp.filename !== '') {
          this.$store.dispatch("sample/uploadFileFromFtp", paramsFtp);
        } else if (paramsFtp.ftp.url !== '') {
          paramsFtp.ftp.filename = paramsFtp.ftp.url.slice(paramsFtp.ftp.url.lastIndexOf('/') + 1);
          this.$store.dispatch("sample/uploadFileFromFtp", paramsFtp);
        }
        this.clearUploadFiles();
      },

      clearUploadFiles() {
        this.uploadFileDialog = false;
        this.filesToUpload = {};
        this.urlsToUpload = {};
        this.newUrl = {};
        this.ftp = {
          filename: '',
          password: '',
          url: '',
          username: '',
        }
      },

      updateSample(uuid) {
        const params = {
          uuid: uuid,
          name: this.newSampleInfo.name,
          description: this.newSampleInfo.description
        };

        this.$store.dispatch('sample/updateSample', params);
        this.newSampleName = '';
        this.newSampleDescription = '';
        this.changeNameDialog = false
      },

      deleteProperty(key) {
        this.$store.dispatch('sample/deleteProperty', {
          uuid: this.uuid,
          key: key
        });
      },

      addProperty(payload) {
        const newProperties = {
          uuid: this.uuid,
          data: [
            {
              key: payload.key,
              value: payload.value
            }
          ]
        };
        this.$store.dispatch(payload.overwrite ? 'sample/overwriteProperty' : 'sample/addProperty', newProperties);
      },

      startNewAnalysis() {
        let name = this.uuid;
        this.$store.dispatch('workflow/getProdWorkflows');
        this.$router.push({path: `/analyses/new/${name}`});
      },

      goToAnalysis(id) {
        this.$router.push({path: `/analyses/${id}`});
      },

      extendValidity() {
        this.$store.dispatch('sample/extendValidity', {
          time: this.validityExtension,
          uuid: this.sample.details.uuid
        });
        this.extendValidityDialog = false
      },

      deleteSample() {
        if (this.deleteDialogEnteredName === this.sample.details.name) {
          this.deleteDialog = false;
          this.$store.dispatch('sample/deleteSample', {uuid: this.uuid});
          this.$router.push('/');
        } else {
          this.deletedNotification = "Name doesn't match, sample hasn't been deleted."
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.error-text {
  margin-left: 30px;
  color: var(--danger-color);
}

.link {
  text-decoration: underline;
  color: var(--primary-color);
  cursor: pointer;
}

.iseq-sample-detail {

  &__analysis,
  &__files,
  &__properties {
    &__title {
      display: flex;
      flex-direction: row;
      color: var(--font-color);
      font-weight: bold;
      font-size: 20px;
    }
  }

  &__base-info {
    &__button-row {
      display: flex;
      justify-content: flex-end;
    }

    &__dates-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .icon {
        color: var(--primary-color);
        margin-right: 5px;
        margin-bottom: 3px;
      }

      .thick {
        color: var(--font-color);
        font-weight: bold;
        font-size: 13px;
      }

      .thin {
        font-size: 14px;
        color: var(--font-inverted-color);
      }
    }

    &__description {
      padding: 20px 0px 15px;
      margin-left: 2px;
      font-size: 14px;
      color: var(--font-inverted-color);
    }
  }

  &__tabs {
    &__wrapper {
      display: flex;

      .iseq-text-field {
        margin: 4px;
      }

      &__tab {
        .icon {
          margin-right: 16px;
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 2rem;
          letter-spacing: 0.0125em;
        }
      }
    }
  }

  &__analysis {
    &__field {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__file {
      &__header {
        align-items: center;
        display: flex;
        padding: 16px;
        margin-bottom: 16px;

        &__extensions {
          background-color: var(--tertiary-color);
        }
      }

      &__spinner {
        padding-bottom: 25px;
      }
    }
  }

  &__files-upload {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title-row {
      margin-bottom: 10px;
      width: 100%;

      &__title {
        color: var(--font-color);
        font-weight: bold;
        font-size: 20px;
      }
    }

    &__tabs {
      width: 100%;
      margin-bottom: 10px;

      &__tab {

      }
    }

    &__files-form {
      width: 90%;
      padding: 10px;
      margin-bottom: 10px;
    }

    &__extensions {
      margin-left: 33px;
      color: var(--font-inverted-color);
      margin-top: 4px;
    }

    &__info {
      margin-left: 43px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid var(--tertiary-color);
      background: var(--light-2);
      margin-bottom: 10px;
      margin-top: 30px;
      display: flex;
      flex-direction: row;

      &__icon {
        align-content: center;
        margin-right: 10px;
      }

      &__text {
        font-weight: bold;
      }
    }

    &__buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.iseq-icon {
  margin-left: 10px;

  &--blue {
    color: var(--primary-color) !important;
  }

  &--red {
    color: var(--danger-color) !important;
  }
}

@media screen and (max-width: 1270px) {
  .iseq-sample-detail__actions {
    flex-wrap: wrap;

    &__wrapper {
      &__tab {
        padding: 0 32px;
      }
    }
  }
}
</style>
