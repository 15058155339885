import request from '@/customjs/request.js';
import store from '@/store';

export default {
  namespaced: true,
  state: {
    clientSecret: '',
    intelliseqTransactionToken: '',
    status: '',
    credits: '100',
    paymentDialog: false,
    paymentSteps: 1,
    statusPayment: {},
    loadingBtn: false,
    stripe: {},
    cardData: {},
  },
  getters: {
    loadingBtn(state) {
      return state.loadingBtn;
    },
    clientSecret(state) {
      return state.clientSecret;
    },
    credits(state) {
      return state.credits;
    },
    paymentDialog(state) {
      return state.paymentDialog;
    },
    paymentSteps(state) {
      return state.paymentSteps;
    },
    statusPayment(state) {
      return state.statusPayment;
    },
    intelliseqTransactionToken(state) {
      return state.intelliseqTransactionToken;
    },
    status(state) {
      return state.status;
    },
    stripe(state) {
      return state.stripe;
    },
    cardData(state) {
      return state.cardData;
    },
  },
  mutations: {
    setClientSecret(state, payload) {
      state.clientSecret = payload;
    },
    setIntelliseqTransactionToken(state, payload) {
      state.intelliseqTransactionToken = payload;
    },
    setCredits(state, payload) {
      state.credits = payload;
    },
    setPaymentDialog(state, payload) {
      state.paymentDialog = payload;
    },
    setPaymentSteps(state, payload) {
      state.paymentSteps = payload;
    },
    setStatusPayment(state, payload) {
      state.statusPayment = payload;
    },
    setStatus(state, payload) {
      state.status = payload;
    },
    setLoadingBtn(state, payload) {
      state.loadingBtn = payload;
    },
    setStripe(state, payload) {
      state.stripe = payload;
    },
    setCardData(state, payload) {
      state.cardData = payload;
    },
  },
  actions: {
    createPaymentIntent({state, commit}) {
      commit('setLoadingBtn', true);
      // Multiplying by 100 because API uses integer value in lowest part of currency
      const cost = parseInt(state.credits) * 100;
      const organizationUuid = store.getters["organization/currentOrganizationUuid"];
      request.request({
        endpoint: `payments/intent`,
        method: 'post',
        data: {
          amount: cost,
          currency: "USD",
          organizationUuid: organizationUuid
        },
        callback: function (data) {
          commit('setLoadingBtn', false);
          commit('setClientSecret', data.clientSecret);
          commit('setIntelliseqTransactionToken', data.iseqTransactionToken);
          commit('setStatus', data.status);

          if (data.status === 'card_assigned') {
            commit('setPaymentSteps', 3);
          } else {
            commit('setPaymentSteps', 2);
          }
        },
        errorCallback: function (error) {
          commit('setLoadingBtn', false);
        },
      });
    },
    completeTransaction({state, commit, dispatch}) {
      commit('setLoadingBtn', true);

      if (state.status === 'card_assigned') {
        request.request({
          endpoint: `payments/charge`,
          method: 'post',
          callback: function (data) {
            commit('setLoadingBtn', false);
            commit('setPaymentSteps', 4);
            commit('setStatusPayment', {paymentIntent: data.status});
            store.dispatch('user/getCredits', {root: true});
          },
          errorCallback: function (error) {
            commit('setLoadingBtn', false);
          },
        });
      } else {
        const data = state.cardData;
        state.stripe.confirmCardPayment(state.clientSecret, {
          ...data,
        }).then(result => {
          request.request({
            endpoint: `payments/finalize`,
            method: 'post',
            callback: function (data) {
              commit('setLoadingBtn', false);
              commit('setPaymentSteps', 4);
              commit('setStatusPayment', {paymentIntent: data.status});
              store.dispatch('user/getCredits', {root: true});
            },
            errorCallback: function (error) {
              commit('setLoadingBtn', false);
            },
          });
        });
      }
    },
  },
};
